import { LRUCache } from 'lru-cache'
import { hash as ohash } from 'ohash'
import { Game, GameItem, PageResult } from '../interfaces/games';

const promiseCache = new LRUCache<string, any>({
  max: 500,
  ttl: 1, 
})




export interface GameListQuery {
  perPage?: number;       
  page?: number; 
  isFeatured?: boolean;
}

export async function gameList(body: GameListQuery = {} ): Promise<any> {

  const url = `/api/categories`;
  return  $fetch(url, {
      method: 'POST',
      body: body
    }).then((res) => {
      return res
    }).catch((e) => {
      throw e
    });
}

export async function gameDetail(gameId: number): Promise<any> {
  
  const url = `/api/categories/${gameId}`;
  return  $fetch(url, {
      method: 'POST'
    }).then((res) => {
      return res
    }).catch((e) => {
      throw e
    });

  return null;
}

export async function gameItems(id: string| number, page: number = 1): Promise<PageResult<GameItem>> {

  const url = `/api/products/${id}`;
  const hash = ohash([url])
  const state = useState<any>(hash, () => null)
  if (state.value)
    return state.value

  if (!promiseCache.has(hash)) {
    promiseCache.set(
      hash,
      $fetch(url, {
        method: 'POST'
      }).then((res) => {
        state.value = res
        return res
      }).catch((e) => {
        promiseCache.delete(hash)
        throw e
      })
    )
  }

  return promiseCache.get(hash);
}

export async function gameFeatureList(page: number = 1): Promise<PageResult<Game>> {

  const url = `/api/products/featured`;
  const hash = ohash([url])
  const state = useState<any>(hash, () => null)
  if (state.value)
    return state.value
      
  if (!promiseCache.has(hash)) {
    promiseCache.set(
      hash,
      $fetch(url, {
        method: 'POST'
      }).then((res) => {
        state.value = res
        return res
      }).catch((e) => {
        promiseCache.delete(hash)
        throw e
      })
    )
  }

  return promiseCache.get(hash);
}

export async function validateAccount(id: string| number, page: number = 1): Promise<PageResult<GameItem>> {

  const url = `/api/users/search`;
  const hash = ohash([url])
  const state = useState<any>(hash, () => null)
  if (state.value)
    return state.value

  if (!promiseCache.has(hash)) {
    promiseCache.set(
      hash,
      $fetch(url, {
        method: 'POST'
      }).then((res) => {
        state.value = res
        return res
      }).catch((e) => {
        promiseCache.delete(hash)
        throw e
      })
    )
  }

  return promiseCache.get(hash);
}

export async function listMedias(page: number = 1): Promise<PageResult<Game>> {

  const url = `/api/medias`;
  const hash = ohash([url])
  const state = useState<any>(hash, () => null)
  if (state.value)
    return state.value
      
  if (!promiseCache.has(hash)) {
    promiseCache.set(
      hash,
      $fetch(url, {
        method: 'POST'
      }).then((res) => {
        state.value = res
        return res
      }).catch((e) => {
        promiseCache.delete(hash)
        throw e
      })
    )
  }

  return promiseCache.get(hash);
}